import { Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import axios from 'axios';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import ColorStep from '../components/steps/Color';
import CompetencesStep from '../components/steps/Competences';
import ConfirmStep from '../components/steps/Confirm';
import EmailStep from '../components/steps/Email';
import InterestsStep from '../components/steps/Interests';
import NameStep from '../components/steps/Name';
import ParamsStep from '../components/steps/Params';
import TagsStep from '../components/steps/Tags';
import UserStep from '../components/steps/User';
import UnderConstruction from '../images/underconstruction.gif';
import theme from '../theme';

const StepPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 40;
  width: 100%;
  margin-top: 30px;
`;

const Creating = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 650px;
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
  .title {
    font-size: 18px !important;
    font-weight: 500 !important;
    font-family: Quicksand !important;
    ${theme.breakpoints.down('sm')} {
      font-size: 16px !important;
    }
  }
  padding-bottom: 20px;
  border-bottom: 1px solid ${theme.palette.blue.main};
`;

type Step =
  | 'email'
  | 'confirm'
  | 'user'
  | 'name'
  | 'params'
  | 'color'
  | 'tags'
  | 'interests'
  | 'competences';

const steps = {
  email: EmailStep,
  confirm: ConfirmStep,
  user: UserStep,
  name: NameStep,
  params: ParamsStep,
  color: ColorStep,
  tags: TagsStep,
  interests: InterestsStep,
  competences: CompetencesStep,
};

// tslint:disable-next-line:no-empty-interface
interface Props extends RouteComponentProps {}

@observer
class CreationPage extends React.Component<Props> {
  @observable
  public step: Step = 'email';

  @observable
  public data: any = {};

  @computed
  get stepNames() {
    return Object.keys(steps);
  }

  @action.bound
  public async gotoNextStep() {
    const current = this.stepIndex;
    if (current === -1) throw new Error('Unexpected result');
    if (current === this.stepNames.length - 1) {
      const url = (process.env.GATSBY_CAMPUS_ORG_API_URL as string).replace(
        '{slug}',
        this.data.organization.slug
      );

      await axios.post(
        `${url}/organizations/update_settings/`,
        {
          theme: { primary: this.data.color },
          tags: this.data.tags,
          competences: this.data.competences,
          interests: this.data.interests,
        },
        {
          headers: {
            Authorization: `Bearer ${this.data.token}`,
          },
        }
      );

      window.location.href = `${(process.env
        .GATSBY_CAMPUS_APP_URL as string).replace(
        '{slug}',
        this.data.organization.slug
      )}?auth_token=${this.data.token}&file_token=${this.data.file_token}`;
      return;
    }
    this.step = this.stepNames[current + 1] as Step;
  }

  @action.bound
  public gotoPreviousStep() {
    const current = this.stepIndex;
    if (current === -1) throw new Error('Unexpected result');
    if (current === 0) return;

    this.step = this.stepNames[current - 1] as Step;
  }

  @action.bound
  public setData(data: any) {
    this.data = { ...this.data, ...data };
  }

  @computed
  get stepIndex() {
    return this.stepNames.indexOf(this.step);
  }

  @computed
  get creating() {
    return this.stepIndex >= 4;
  }

  public render() {
    const StepComponent = steps[this.step];
    return (
      <Layout isCreatePage>
        <StepPage>
          {this.creating && (
            <Creating>
              <img
                src={UnderConstruction}
                style={{
                  maxHeight: '200px',
                  maxWidth: '100%',
                  alignSelf: 'center',
                }}
              />
              <Typography color="primary" align="center" className="title">
                Votre Campus est en construction, il sera prêt dans quelques
                secondes ...
              </Typography>
              <Typography
                style={{
                  color: theme.palette.blue.main,
                  fontWeight: 300,
                  fontSize: '17px',
                  marginBottom: '10px',
                  marginTop: '5px',
                }}
                align="center"
              >
                En attendant, vous pouvez commencer à paramétrer votre Campus.
              </Typography>
            </Creating>
          )}
          <StepComponent
            data={this.data}
            gotoNextStep={this.gotoNextStep}
            gotoPreviousStep={this.gotoPreviousStep}
            setData={this.setData}
          />
          {this.creating && (
            <Typography
              variant="caption"
              align="center"
              style={{
                marginTop: '20px',
                fontSize: '14px',
                color: theme.palette.grey[400],
              }}
            >
              Vous pourrez modifier ces paramètres plus tard
            </Typography>
          )}
        </StepPage>
      </Layout>
    );
  }
}

export default CreationPage;
