import { Button, TextField, Typography } from '@material-ui/core';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { StepProps } from './types';
import theme from '../../theme';
import SkillsIcon from '../../images/icons/skills.svg';

const Title = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &&&& {
    margin-bottom: 10px;
    font-weight: 500;
    font-family: Quicksand;
    color: ${theme.palette.blue.main};
    font-size: 20px;
    ${theme.breakpoints.down('sm')} {
      font-size: 15px;
      padding: 0;
    }
  }
`;

const Wrapper = styled.form`
  max-width: 650px;
  width: 100%;
`;

const Competences = styled.div`
  display: flex;
  align-items: center;
  justify-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -2px;
  margin-bottom: 20px;
`;

const Competence = styled(Typography)`
  &&& {
    padding: 4px 10px;
    border: 1px solid #003366;
    border-radius: 4px;
    color: #003366;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .remove {
    display: inline-block;
    cursor: pointer;
    font-weight: 500;
    margin-left: 7px;
  }
`;

@observer
class CompetencesStep extends React.Component<StepProps> {
  @observable
  public competences: string[] = [
    'Électronique',
    'Mécanique',
    'Impression 3D',
    'Graphisme',
    'Créativité',
    'Modélisation 3D',
    'Marketing',
    'Montage Vidéo',
    'Vente',
    'Étude de marché',
    'Design',
    'Photos',
    'Pitch',
    'Juridique',
    'Développement Informatique',
    'Industrialisation',
    'Gestion de projet',
    'Cahier des charges',
    'Finance',
    'Business model',
  ];

  @observable
  public currentCompetence: string = '';

  public componentWillMount() {
    this.onSubmit();
  }

  @action
  public handleCurrentCompetenceUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.currentCompetence = e.target.value;
  }

  @action
  public addCompetence = (competence: string) => {
    this.competences.push(competence);
  }

  @action
  public onSubmitCompetence = (e?: React.MouseEvent<HTMLElement>) => {
    if (this.currentCompetence.length === 0) return;
    if (this.competences.indexOf(this.currentCompetence) !== -1) return;
    this.competences.push(this.currentCompetence);
    this.currentCompetence = '';
  }

  public onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    this.props.setData({ competences: this.competences });
    this.props.gotoNextStep();
    return false;
  }

  public render() {
    return null;
    return (
      <Wrapper onSubmit={this.onSubmit}>
        <Title>
          <SkillsIcon
            height="20"
            width="20"
            style={{ marginBottom: '-3px', marginRight: '7px' }}
          />
          Compétences
        </Title>
        <Typography
          align="justify"
          style={{
            color: theme.palette.blue.main,
            fontWeight: 400,
            marginTop: '20px',
            marginBottom: '30px',
          }}
        >
          Notre algorithme se sert des{' '}
          <b style={{ fontWeight: 600 }}>compétences</b> de vos collaborateurs
          pour matcher des profils complémentaires. Nous vous proposons ici une
          liste de <b style={{ fontWeight: 600 }}>compétences</b> génériques
          mais n'hésitez pas à en ajouter ou à en supprimer suivant vos besoins.
          Vos collaborateurs pourront en choisir parmi cette liste.
        </Typography>
        <Competences
          style={{
            marginTop: '20px',
          }}
        >
          {this.competences.map(competence => {
            const removeCompetence = () =>
              runInAction(() => {
                this.competences = this.competences.filter(
                  t => t !== competence,
                );
              });
            return (
              <Competence key={competence}>
                {competence}
                <span className="remove" onClick={removeCompetence}>
                  <i className="fas fa-times" />
                </span>
              </Competence>
            );
          })}
        </Competences>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '10px',
            marginBottom: '20px',
          }}
        >
          <TextField
            label="Compétence"
            value={this.currentCompetence}
            onChange={this.handleCurrentCompetenceUpdate}
            // tslint:disable-next-line:jsx-no-lambda
            onKeyDown={e => {
              if (e.keyCode === 13) {
                e.preventDefault();
                e.stopPropagation();
                this.onSubmitCompetence();
                return false;
              }
              return true;
            }}
          />
          <Button
            style={{ marginTop: '10px', marginLeft: '10px' }}
            variant="outlined"
            onClick={this.onSubmitCompetence}
          >
            Ajouter
          </Button>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Button
            style={{ marginTop: '10px', padding: '0 30px', marginRight: '7px' }}
            onClick={this.props.gotoPreviousStep}
          >
            Retour
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '10px' }}
            type="submit"
          >
            Aller sur le Campus
          </Button>
        </div>
      </Wrapper>
    );
  }
}

export default CompetencesStep;
