import {
  action,
  computed,
  observable,
  runInAction
  } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { StepProps } from './types';
import api from '../../api';
import Campus from '../../images/campus.png';
import theme from '../../theme';
import {
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

const Title = styled(Typography)`
  &&&& {
    margin-bottom: 10px;
    font-weight: 500;
    font-family: Quicksand;
    color: ${theme.palette.blue.main};
    font-size: 25px;
    ${theme.breakpoints.down('sm')} {
      font-size: 18px;
      padding: 0;
    }
  }
`;

const Wrapper = styled.form`
  max-width: 400px;
  width: 100%;
  margin-top: 20px;
`;

const slugify = (text: string) => {
  return text
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

@observer
class NameStep extends React.Component<StepProps> {
  @observable
  public name: string = '';

  @observable
  public loading: boolean = false;

  @observable
  public error: boolean = false;

  @observable
  public popup: boolean = false;

  @action
  public handleNameUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.name = e.target.value;
    this.error = false;
  };

  @action
  public closePopup = () => {
    this.popup = false;
    this.loading = false;
  };

  @computed
  get slug() {
    return slugify(this.name);
  }

  @action
  public onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.setData({ name: this.name, slug: this.slug });
    this.loading = true;
    try {
      const { data } = await api.post('/create_organization/', {
        code: this.props.data.code,
        name: this.name,
        fullname: this.props.data.username,
        password: this.props.data.password,
      });

      this.props.setData({
        organization: data.organization,
        token: data.token,
        file_token: data.file_token,
      });

      runInAction(() => {
        this.popup = true;
        this.loading = false;
        this.error = false;
      });
    } catch (e) {
      runInAction(() => {
        this.popup = false;
        this.loading = false;
        this.error = true;
      });
    }
    return false;
  };

  @action
  public onPopupClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    this.props.gotoNextStep();
  };

  public render() {
    return (
      <Wrapper onSubmit={this.onSubmit}>
        {this.popup && (
          <Dialog open onClose={this.closePopup} style={{ margin: '-35px' }}>
            <div style={{ width: '600px', maxWidth: '100%', margin: '0 auto' }}>
              <DialogTitle style={{ padding: '28px 28px' }}>
                <Title>Finaliser la création de votre Campus</Title>
              </DialogTitle>
              <DialogContent style={{ padding: '0 28px 28px' }}>
                <DialogContentText style={{ marginBottom: '20px' }}>
                  En cliquant sur "J'accepte", vous comprenez et acceptez sans
                  réserve les{' '}
                  <a
                    href="https://s3.eu-west-3.amazonaws.com/lfai-legals/Cgu-campus-pro.pdf"
                    target="_blank"
                  >
                    Conditions Générales d'Utilisation
                  </a>{' '}
                  du Campus.
                </DialogContentText>
                <DialogContentText
                  style={{ color: theme.palette.blue.main, margin: '40px 0' }}
                >
                  Par ailleurs, vous recevrez des emails pour vous aider et vous
                  conseiller sur la bonne mise en place et le développement de
                  votre Campus.
                </DialogContentText>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={this.onPopupClick}
                  >
                    J'accepte
                  </Button>
                </div>
              </DialogContent>
            </div>
          </Dialog>
        )}
        <Title>Comment s'appelle votre société ou votre groupe ?</Title>
        <Typography
          style={{
            color: theme.palette.blue.main,
            marginBottom: '10px',
            fontWeight: 300,
          }}
        >
          Nous l'utiliserons pour nommer votre Campus.
        </Typography>
        <div style={{ margin: '30px 0', marginTop: '10px' }}>
          <TextField
            margin="normal"
            fullWidth
            label="Nom de votre société ou groupe"
            placeholder="Ex. ABC ou ABC Innovation"
            autoFocus
            value={this.name}
            onChange={this.handleNameUpdate}
            helperText={this.error ? 'Ce nom est déja utilisé' : ''}
            error={this.error}
            variant="outlined"
          />
          <Typography
            style={{ color: theme.palette.blue.main, marginTop: '10px' }}
          >
            Votre Campus sera accessible à l'adresse{' '}
            <span style={{ fontWeight: 500 }}>
              {this.slug}
              .lfai.co
            </span>
          </Typography>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            type="submit"
            disabled={this.loading || this.name.length <= 1}
            color="primary"
            style={{ marginTop: '10px' }}
          >
            {this.loading ? 'Vérification en cours' : 'Créer le Campus'}
          </Button>
        </div>
        <img src={Campus} style={{ width: '100%', marginTop: '70px' }} />
      </Wrapper>
    );
  }
}

export default NameStep;
