import { Button, TextField, Typography } from '@material-ui/core';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { StepProps } from './types';
import api from '../../api';
import theme from '../../theme';
import Campus from '../../images/campus.png';

const Title = styled(Typography)`
  &&&& {
    font-weight: 500;
    font-family: Quicksand;
    color: ${theme.palette.blue.main};
    font-size: 25px;
    ${theme.breakpoints.down('sm')} {
      font-size: 18px;
      padding: 0;
    }
  }
`;

const Wrapper = styled.form`
  max-width: 400px;
  width: 100%;
  margin-top: 40px;
`;

@observer
class ConfirmStep extends React.Component<StepProps> {
  @observable
  public code: string = '';

  @observable
  public dirty: boolean = true;

  @observable
  public error: boolean = false;

  @action
  public handleCodeUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.code = e.target.value
      .split('')
      .filter(c => '0123456789'.indexOf(c) !== -1)
      .join('')
      .slice(0, 6);
    if (this.code.length) this.dirty = false;
  }

  public onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.setData({ code: this.code });

    try {
      const { data } = await api.post('/verify_code/', {
        code: this.code,
      });
      if (data.valid) {
        this.props.gotoNextStep();
      } else {
        runInAction(() => {
          this.error = true;
        });
      }
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    }
    return false;
  }

  public render() {
    const { data } = this.props;
    return (
      <Wrapper onSubmit={this.onSubmit}>
        <Title>Confirmer votre email</Title>
        <Typography
          style={{
            color: theme.palette.blue.main,
            marginBottom: '10px',
            fontWeight: 300,
          }}
        >
          Nous vous avons envoyé un code de confirmation à 6 chiffres à
          l'adresse {data.email}. Attention, ce code n'est valide que pendant
          24h.
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          variant="outlined"
          label="Code"
          placeholder="XXXXXX"
          style={{ margin: '30px 0' }}
          autoFocus
          value={this.code}
          onChange={this.handleCodeUpdate}
          error={this.error}
          helperText={this.error ? "Ce code n'est pas/plus valide" : ''}
        />
        <div style={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '20px', padding: '0 40px' }}
            disabled={this.code.length !== 6}
            type="submit"
          >
            Confirmer
          </Button>
        </div>
        <img src={Campus} style={{ width: '100%', marginTop: '70px' }} />
      </Wrapper>
    );
  }
}

export default ConfirmStep;
