import { StepProps } from './types';
import { Button } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.form`
  max-width: 650px;
  width: 100%;
  text-align: center;
`;

@observer
class ParamsStep extends React.Component<StepProps> {
  public onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.gotoNextStep();
    return false;
  }

  public render() {
    return (
      <Wrapper onSubmit={this.onSubmit}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '10px', padding: '0 40px' }}
          type="submit"
        >
          Paramétrer votre Campus
        </Button>
      </Wrapper>
    );
  }
}

export default ParamsStep;
