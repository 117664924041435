import { Button, TextField, Typography } from '@material-ui/core';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import { StepProps } from './types';
import api from '../../api';
import Campus from '../../images/campus.png';
import theme from '../../theme';

const Title = styled(Typography)`
  &&&& {
    font-weight: 500;
    font-family: Quicksand;
    color: ${theme.palette.blue.main};
    font-size: 25px;
    ${theme.breakpoints.down('sm')} {
      font-size: 18px;
      padding: 0;
    }
  }
`;

const Wrapper = styled.form`
  max-width: 400px;
  width: 100%;
  margin-top: 40px;
`;

const MAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@observer
class EmailStep extends React.Component<StepProps> {
  @observable
  public email: string = '';

  @observable
  public dirty: boolean = true;

  @action
  public handleEmailUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.email = e.target.value;
    this.dirty = false;
  }

  @computed
  get error() {
    return !this.dirty && !MAIL_REGEX.test(this.email);
  }

  public onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.setData({ email: this.email });

    await api.post('/request_code/', {
      email: this.email,
    });

    this.props.gotoNextStep();
    return false;
  }

  public render() {
    return (
      <Wrapper onSubmit={this.onSubmit}>
        <Title>Créer votre Campus</Title>
        <Typography
          style={{
            color: theme.palette.blue.main,
            marginBottom: '10px',
            fontWeight: 300,
          }}
        >
          Merci de renseigner votre adresse email
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          label="Email"
          autoFocus
          placeholder="exemple@entreprise.com"
          value={this.email}
          variant="outlined"
          onChange={this.handleEmailUpdate}
          style={{ margin: '30px 0' }}
          error={this.error}
          helperText={this.error ? 'Votre adresse email est invalide' : ''}
        />
        <div style={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '20px', padding: '0 40px' }}
            type="submit"
            disabled={this.dirty || this.error}
          >
            Continuer
          </Button>
        </div>
        <img src={Campus} style={{ width: '100%', marginTop: '70px' }} />
      </Wrapper>
    );
  }
}

export default EmailStep;
