import React from 'react';
import styled from 'styled-components';
import { Typography, Button } from '@material-ui/core';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import theme from '../../theme';
import { StepProps } from './types';
import ColorIcon from '../../images/icons/color.svg';
import CampusColor from '../../images/campus_color.svg';

const Title = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &&&& {
    margin-bottom: 10px;
    font-weight: 500;
    font-family: Quicksand;
    color: ${theme.palette.blue.main};
    font-size: 20px;
    ${theme.breakpoints.down('sm')} {
      font-size: 15px;
      padding: 0;
    }
  }
`;

const Wrapper = styled.form`
  max-width: 650px;
  width: 100%;
`;

const Colors = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -2px;
`;

const Color = styled.div`
  height: 30px;
  width: 30px;
  margin: 2px;
`;

const ColorSvg = styled.div`
  max-width: 500px;
  width: 70%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin: 30px auto;
  svg {
    display: block;
    width: 100%;
  }
`;

const COLORS = [
  '#F44336',
  '#FF1744',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#FF9800',
  '#FF5722',
  '#795548',
];

@observer
class ColorStep extends React.Component<StepProps> {
  @observable
  public color: string = '#3F51B5';

  @action
  public handleColorUpdate = (color: string) => (
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    this.color = color;
  }

  public onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.setData({ color: this.color });
    this.props.gotoNextStep();
    return false;
  }

  public render() {
    return (
      <Wrapper onSubmit={this.onSubmit}>
        <Title>
          <ColorIcon
            height="25"
            width="25"
            style={{ marginRight: '8px', marginBottom: '-5px' }}
          />
          Couleur de votre Campus
        </Title>
        <Typography
          style={{
            color: theme.palette.blue.main,
            fontWeight: 300,
            fontSize: '16px',
            marginBottom: '20px',
          }}
          align="center"
        >
          Choisissez la couleur de votre Campus
        </Typography>
        <Colors>
          {COLORS.map(color => (
            <Color
              key={color}
              style={{
                backgroundColor: color,
                border:
                  color === this.color
                    ? '5px solid rgba(0, 0, 0, 0.5)'
                    : undefined,
              }}
              onClick={this.handleColorUpdate(color)}
            />
          ))}
        </Colors>
        <div
          style={{
            color: this.color,
            textAlign: 'center',
            marginBottom: '10px',
          }}
        >
          <ColorSvg>
            <CampusColor />
          </ColorSvg>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '10px', padding: '0 30px' }}
            type="submit"
          >
            Suivant
          </Button>
        </div>
      </Wrapper>
    );
  }
}

export default ColorStep;
