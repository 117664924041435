import { Button, TextField, Typography } from '@material-ui/core';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { StepProps } from './types';
import theme from '../../theme';
import TagIcon from '../../images/icons/tag.svg';

const Title = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &&&& {
    margin-bottom: 10px;
    font-weight: 500;
    font-family: Quicksand;
    color: ${theme.palette.blue.main};
    font-size: 20px;
    ${theme.breakpoints.down('sm')} {
      font-size: 15px;
      padding: 0;
    }
  }
`;

const Wrapper = styled.form`
  max-width: 650px;
  width: 100%;
`;

const Tags = styled.div`
  display: flex;
  align-items: center;
  justify-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -2px;
  margin-bottom: 20px;
`;

const Tag = styled(Typography)`
  &&& {
    padding: 4px 10px;
    border: 1px solid #003366;
    border-radius: 4px;
    color: #003366;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .remove {
    display: inline-block;
    cursor: pointer;
    font-weight: 500;
    margin-left: 7px;
  }
`;

@observer
class TagsStep extends React.Component<StepProps> {
  @observable
  public tags: string[] = [
    'Innovation',
    'Qualité',
    'Optimisation',
    'Environnement',
    'Réduction des coûts',
    'Bien être au travail',
    'Satisfaction client',
    'R&D',
    'Communication',
    'Astuces',
    'Stratégie',
    'Autres',
  ];

  @observable
  public currentTag: string = '';

  @action
  public handleCurrentTagUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.currentTag = e.target.value;
  }

  @action
  public addTag = (tag: string) => {
    this.tags.push(tag);
  }

  @action
  public onSubmitTag = (e?: React.MouseEvent<HTMLElement>) => {
    if (this.currentTag.length === 0) return;
    if (this.tags.indexOf(this.currentTag) !== -1) return;
    this.tags.push(this.currentTag);
    this.currentTag = '';
  }

  public onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.setData({ tags: this.tags });
    this.props.gotoNextStep();
    return false;
  }

  public render() {
    return (
      <Wrapper onSubmit={this.onSubmit}>
        <Title>
          <TagIcon
            height="20"
            width="20"
            style={{ marginBottom: '-3px', marginRight: '7px' }}
          />
          Tags
        </Title>
        <Typography
          align="justify"
          style={{
            color: theme.palette.blue.main,
            fontWeight: 400,
            marginTop: '20px',
            marginBottom: '30px',
          }}
        >
          Les <b style={{ fontWeight: 600 }}>tags</b> permettent à vos
          collaborateurs de catégoriser leurs contributions sur le Campus. Ils
          pourront choisir parmi cette liste. Nous vous en proposons
          quelques-uns mais n'hésitez pas à en ajouter ou à en supprimer suivant
          vos besoins.
        </Typography>
        <Tags
          style={{
            marginTop: '20px',
          }}
        >
          {this.tags.map(tag => {
            const removeTag = () =>
              runInAction(() => {
                this.tags = this.tags.filter(t => t !== tag);
              });
            return (
              <Tag key={tag}>
                {tag}
                <span className="remove" onClick={removeTag}>
                  <i className="fas fa-times" />
                </span>
              </Tag>
            );
          })}
        </Tags>
        <div
          style={{
            display: 'flex',
            marginTop: '10px',
            marginBottom: '20px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextField
            label="Tag"
            value={this.currentTag}
            onChange={this.handleCurrentTagUpdate}
            // tslint:disable-next-line:jsx-no-lambda
            onKeyDown={e => {
              if (e.keyCode === 13) {
                e.preventDefault();
                e.stopPropagation();
                this.onSubmitTag();
                return false;
              }
              return true;
            }}
          />
          <Button
            style={{ marginTop: '10px', marginLeft: '10px' }}
            variant="outlined"
            onClick={this.onSubmitTag}
          >
            Ajouter
          </Button>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Button
            style={{ marginTop: '10px', padding: '0 30px', marginRight: '7px' }}
            onClick={this.props.gotoPreviousStep}
          >
            Retour
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '10px', padding: '0 30px' }}
            type="submit"
          >
            Suivant
          </Button>
        </div>
      </Wrapper>
    );
  }
}

export default TagsStep;
