import { Button, TextField, Typography } from '@material-ui/core';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { StepProps } from './types';
import theme from '../../theme';
import InterestIcon from '../../images/icons/interests.svg';

const Title = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &&&& {
    margin-bottom: 10px;
    font-weight: 500;
    font-family: Quicksand;
    color: ${theme.palette.blue.main};
    font-size: 20px;
    ${theme.breakpoints.down('sm')} {
      font-size: 15px;
      padding: 0;
    }
  }
`;

const Wrapper = styled.form`
  max-width: 650px;
  width: 100%;
`;

const Interests = styled.div`
  display: flex;
  align-items: center;
  justify-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -2px;
  margin-bottom: 20px;
`;

const Interest = styled(Typography)`
  &&& {
    padding: 4px 10px;
    border: 1px solid #003366;
    border-radius: 4px;
    color: #003366;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .remove {
    display: inline-block;
    cursor: pointer;
    font-weight: 500;
    margin-left: 7px;
  }
`;

@observer
class InterestsStep extends React.Component<StepProps> {
  @observable
  public interests: string[] = [
    'Sport',
    'Musique',
    'Bénévolat',
    'Startup',
    'Écologie',
    'Philosophie',
    'Voyages',
    'High Tech',
    'Innovation',
    'Do It Yourself',
    'Art',
    'Cuisine',
    'Digital',
    'Politique',
    'Société',
    'Médias & réseaux sociaux',
  ];

  @observable
  public currentInterest: string = '';

  public componentWillMount() {
    this.onSubmit();
  }

  @action
  public handleCurrentInterestUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.currentInterest = e.target.value;
  }

  @action
  public addInterest = (interest: string) => {
    this.interests.push(interest);
  }

  @action
  public onSubmitInterest = (e?: React.MouseEvent<HTMLElement>) => {
    if (this.currentInterest.length === 0) return;
    if (this.interests.indexOf(this.currentInterest) !== -1) return;
    this.interests.push(this.currentInterest);
    this.currentInterest = '';
  }

  public onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    this.props.setData({ interests: this.interests });
    this.props.gotoNextStep();
    return false;
  }

  public render() {
    return null;
    return (
      <Wrapper onSubmit={this.onSubmit}>
        <Title>
          <InterestIcon
            height="20"
            width="20"
            style={{ marginBottom: '-3px', marginRight: '7px' }}
          />
          Centres d'intérêt
        </Title>
        <Typography
          align="justify"
          style={{
            color: theme.palette.blue.main,
            fontWeight: 400,
            marginTop: '20px',
            marginBottom: '30px',
          }}
        >
          Notre algorithme se sert des{' '}
          <b style={{ fontWeight: 600 }}>centres d'intérêt</b> de vos
          collaborateurs pour matcher des profils complémentaires. Nous vous
          proposons ici une liste de{' '}
          <b style={{ fontWeight: 600 }}>centres d'intérêt</b> génériques mais
          n'hésitez pas à en ajouter ou à en supprimer suivant vos besoins. Vos
          collaborateurs pourront en choisir parmi cette liste.
        </Typography>

        <Interests style={{ marginTop: '20px' }}>
          {this.interests.map(interest => {
            const removeInterest = () =>
              runInAction(() => {
                this.interests = this.interests.filter(t => t !== interest);
              });
            return (
              <Interest key={interest}>
                {interest}
                <span className="remove" onClick={removeInterest}>
                  <i className="fas fa-times" />
                </span>
              </Interest>
            );
          })}
        </Interests>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
            marginTop: '10px',
          }}
        >
          <TextField
            label="Intérêt"
            value={this.currentInterest}
            onChange={this.handleCurrentInterestUpdate}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                e.preventDefault();
                e.stopPropagation();
                this.onSubmitInterest();
                return false;
              }
              return true;
            }}
          />
          <Button
            style={{ marginTop: '10px', marginLeft: '10px' }}
            variant="outlined"
            onClick={this.onSubmitInterest}
          >
            Ajouter
          </Button>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Button
            style={{ marginTop: '10px', padding: '0 30px', marginRight: '7px' }}
            onClick={this.props.gotoPreviousStep}
          >
            Retour
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '10px', padding: '0 30px' }}
            type="submit"
          >
            Suivant
          </Button>
        </div>
      </Wrapper>
    );
  }
}

export default InterestsStep;
