import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import {
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import {
  Mail,
  People,
  Lock,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import zxcvbn from 'zxcvbn';

import Campus from '../../images/campus.png';
import { StepProps } from './types';
import theme from '../../theme';

const Title = styled(Typography)`
  &&&& {
    font-weight: 500;
    font-family: Quicksand;
    color: ${theme.palette.blue.main};
    font-size: 25px;
    ${theme.breakpoints.down('sm')} {
      font-size: 18px;
      padding: 0;
    }
  }
`;

const Wrapper = styled.form`
  max-width: 400px;
  width: 100%;
  margin-top: 20px;
`;

@observer
class UserStep extends React.Component<StepProps> {
  @observable
  public username: string = '';

  @observable
  public dirtyUsername: boolean = true;
  @observable
  public password: string = '';

  @observable
  public dirtyPassword: boolean = true;

  @observable
  public showPassword: boolean = false;

  @action
  public handleUsernameUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.username = e.target.value;
    this.dirtyUsername = false;
  }

  @action
  public handlePasswordUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.password = e.target.value;
    this.dirtyPassword = false;
  }

  @computed
  get errorPassword() {
    return !this.dirtyPassword && zxcvbn(this.password).score < 2;
  }

  @computed
  get errorUsername() {
    return !this.dirtyUsername && this.username.length < 4;
  }

  public onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.setData({ username: this.username, password: this.password });
    this.props.gotoNextStep();
    return false;
  }

  @action
  public togglePassword = () => {
    this.showPassword = !this.showPassword;
  }

  public render() {
    return (
      <Wrapper onSubmit={this.onSubmit}>
        <Title>Création de votre compte</Title>
        <Typography
          style={{
            color: theme.palette.blue.main,
            marginBottom: '10px',
            fontWeight: 300,
          }}
        >
          Merci de renseigner votre nom complet tel qu'il sera écrit sur le
          Campus et définir votre mot de passe.
        </Typography>
        <div style={{ margin: '30px 0' }}>
          <TextField
            margin="normal"
            fullWidth
            label="Email"
            value={this.props.data.email}
            variant="outlined"
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Mail />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Nom complet"
            placeholder="Noël Flantier"
            value={this.username}
            variant="outlined"
            onChange={this.handleUsernameUpdate}
            error={this.errorUsername || this.username.length < 4}
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <People />
                </InputAdornment>
              ),
            }}
            helperText={
              this.errorUsername
                ? 'Votre nom complet doit contenir au moins 4 caractères'
                : ''
            }
          />
          <TextField
            margin="normal"
            fullWidth
            type={this.showPassword ? 'text' : 'password'}
            label="Mot de passe"
            placeholder="**********"
            value={this.password}
            variant="outlined"
            onChange={this.handlePasswordUpdate}
            error={this.errorPassword}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {this.showPassword ? (
                    <IconButton onClick={this.togglePassword}>
                      <VisibilityOff />
                    </IconButton>
                  ) : (
                    <IconButton onClick={this.togglePassword}>
                      <Visibility />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            helperText={
              this.errorPassword
                ? "Votre mot de passe n'est pas assez sécurisé"
                : ''
            }
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '20px', padding: '0 40px' }}
            type="submit"
            disabled={
              this.dirtyUsername ||
              this.errorUsername ||
              (this.dirtyPassword || this.errorPassword)
            }
          >
            Continuer
          </Button>
        </div>
        <img src={Campus} style={{ width: '100%', marginTop: '70px' }} />
      </Wrapper>
    );
  }
}

export default UserStep;
